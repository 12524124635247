import React from 'react';
import '../Assets/footer.css'; 

const Footer = () => {
  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <a href="/" className="nav-link px-2 text-body-secondary">Top Page</a>
          </li>
        </ul>
        <p className="text-center text-body-secondary">JP Data Analyst</p>
      </footer>
    </div>
  );
};

export default Footer;
