import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Assets/ProjectsLayout.css';
import { projectsData } from '../components/projectsData.js';

class ProjectsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: projectsData,
    };
  }

  render() {
    return (
      <>
        <h1 className="projects-title" id="projects">Projects</h1>
        <div className="container">
          {this.state.projects.map((project, index) => (
            <div
              className="box"
              key={index}
              style={{ backgroundImage: `url(${project.image})` }}
            >
              <div className="overlay">
                <p>{project.description}</p>
                {project.href_link ? (
                  project.href_link.startsWith('http') ? (
                    <button onClick={() => window.open(project.href_link, '_blank')}>
                      View Project
                    </button>
                  ) : (
                    <Link to={project.href_link} target="_blank">
                      <button>
                        View Project
                      </button>
                    </Link>
                  )
                ) : (
                  <button disabled>
                    No Link Available
                  </button>
                )}
              </div>
              <h2 className="box-title">{project.title}</h2>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default ProjectsLayout;
