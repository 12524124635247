import '../Assets/excel.css';
import React, { Component } from 'react';

const executive_summary = require("../Assets/imgs/excel/executive_summary.png");
const avg_fieldgoal = require("../Assets/imgs/excel/avg_fieldgoal.png");
const grandtotalsbyplayer = require("../Assets/imgs/excel/grandtotalsbyplayer.png");
const sigmapoints = require("../Assets/imgs/excel/sigmapoints.png");
const preparation = require("../Assets/imgs/excel/preparation.png");
const dashboard = require("../Assets/imgs/excel/dashboard.png");
const summary = require("../Assets/imgs/excel/summary.png");

class Knicks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedImage: null,
    };
  }

  openModal = (image) => {
    this.setState({ modalVisible: true, selectedImage: image });
  };

  closeModal = () => {
    this.setState({ modalVisible: false, selectedImage: null });
  };

  render() {
    const { modalVisible, selectedImage } = this.state;

    return (
      <div className="knicks-container">
        <h1 className="knicks-title">NBA Free Throw Data Analysis</h1>
        <p className="project-description">
          This project features an Excel dashboard designed to visualize the New York Knicks' performance,
          aiding strategic coaching decisions. It provides insights into free throw percentages, player performance,
          and game statistics over the season.
        </p>
        <h2 className="technical-tools-title">Technical Tools</h2>
        <ul className="technical-tools-list">
          <li>Microsoft Excel</li>
          <li>Data Visualization</li>
          <li>Pivot Tables</li>
          <li>Charts and Graphs</li>
          <li>Data Analysis Toolpak</li>
        </ul>

        <div className="image-grid">
          {[
            { src: executive_summary, alt: "Executive Summary" },
            { src: preparation, alt: "Preparation" },
            { src: avg_fieldgoal, alt: "Average Field Goal" },
            { src: grandtotalsbyplayer, alt: "Grand Totals by Player" },
            { src: sigmapoints, alt: "Sigma Points" },
            { src: dashboard, alt: "Dashboard" },
            { src: summary, alt: "Summary" },
          ].map((image, index) => (
            <div className="image-item" key={index}>
              <img 
                src={image.src} 
                alt={image.alt} 
                onClick={() => this.openModal(image.src)} 
              />
              <div className="overlay" onClick={() => this.openModal(image.src)}>
                <span>Click Me</span>
              </div>
            </div>
          ))}
        </div>

        {modalVisible && (
          <div className="modal" onClick={this.closeModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <span className="close" onClick={this.closeModal}>&times;</span>
              <img src={selectedImage} alt="Modal View" className="modal-image" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Knicks;
