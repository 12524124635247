const knicks = require("../Assets/imgs/knicks.png");
const netflix = require("../Assets/imgs/netflix.png");
const cars = require("../Assets/imgs/electricity.png");

export const projectsData = [
  {
    "title": "Netflix Media Data Trends",
    "description": "Power BI dashboard to analyze Netflix data, highlighting trends and geographical viewership.",
    "image": netflix,
    "href_link": "/netflix" 
  },
  {
    "title": "NBA Free throw Data Analysis",
    "description": "Excel dashboard to visualize the New York Knicks' performance, aiding strategic coaching decisions.",
    "image": knicks,
    "href_link": "/excel" // Updated to link to Excel page
  },
  {
    "title": "Electric Vehicle Car Analysis",
    "description": "Tableau dashboards to analyze electric vehicle data and regional trends for improved insights.",
    "image": cars,
    "href_link": "https://public.tableau.com/app/profile/jenn.perez/viz/EVAnalysis-WashingtonEVDashboard/Dashboard1?publish=yes"
  }
];
