import React from 'react';
import '../Assets/Summary.css'; 

const Summary = () => {
  return (
    <div className="summary-container">
      <h2 className="summary-title">Data Analyst </h2>
      <p className="summary-description">

    Jennifer Perez is a detail-oriented Data Analyst with a BBA in Computer Information Systems and a strong background in technical support and web development. Proficient in data analysis tools like SQL, Python, Excel, and Power BI, she has successfully developed interactive dashboards for various projects, including Netflix media trends and NBA performance analysis. Her expertise in AWS and data visualization enables her to extract actionable insights, while her recent enhancements in Excel and Power BI skills position her to drive effective data-driven decisions. Jennifer’s collaborative approach and problem-solving abilities ensure impactful contributions to any analytics team.
      </p>
    </div>
  );
};

export default Summary;
