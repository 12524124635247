import "../Assets/netflix.css";
import React, { Component } from "react";

const images = [
  {
    src: require("../Assets/imgs/netflix/overview.png"),
    alt: "Netflix Overview",
  },
  {
    src: require("../Assets/imgs/netflix/single_tileview.png"),
    alt: "Netflix Single Tile View",
  },
];

class Netflix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedImage: null,
    };
  }

  openModal = (image) => {
    console.log("Opening modal with image:", image);
    this.setState({ modalVisible: true, selectedImage: image });
  };

  closeModal = () => {
    this.setState({ modalVisible: false, selectedImage: null });
  };

  render() {
    const { modalVisible, selectedImage } = this.state;
    return (
      <div className="netflix-container">
        <h1 className="netflix-title">Netflix Media Data Trends</h1>
        <p className="project-description">
          Developed an interactive dashboard to analyze and visualize Netflix
          movie and TV show data, highlighting trends, ratings, and geographical
          viewership patterns.
        </p>

        <h2 className="technical-tools-title">Technical Tools</h2>
        <ul className="technical-tools-list">
          <li>Power BI for data visualization</li>
          <li>Excel for data cleaning and manipulation</li>
          <li>Pivot tables and logical functions</li>
          <li>Dynamic dashboards with slicers</li>
          <li>Viewership trend analysis</li>
          <li>Map visualizations for regional patterns</li>
        </ul>

        <div className="image-grid">
          {images.map((image, index) => (
            <div className="image-item" key={index}>
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => this.openModal(image.src)}
              />
              <div
                className="overlay_"
                onClick={() => this.openModal(image.src)}
              >
                Click Me
              </div>
            </div>
          ))}
        </div>

        {modalVisible && (
          <div className="modal_netflix" onClick={this.closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close" onClick={this.closeModal}>
                &times;
              </span>
              <img src={selectedImage} alt="Modal View" />
            </div>
          </div>
        )}

        <div
          className="github-link"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <a
            href="https://github.com/JennMP88/netflixupload"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub: download extension here
          </a>
        </div>
      </div>
    );
  }
}

export default Netflix;
