import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Summary from "./components/summary";
import ProjectsLayout from './components/projects';
import Netflix from './components/netflix_page';
import Excel from './components/excel_page';
import Header from './components/header';
import Footer from './components/footer';

function App() {
  return (
    <Router>
      <Header />
      <Main />
      <Footer /> 
    </Router>
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
      {/* Render Summary only on the main page */}
      {location.pathname === '/' && <Summary />}
      <Routes>
        <Route path="/" element={<ProjectsLayout />} />
        <Route path="/netflix" element={<Netflix />} />
        <Route path="/excel" element={<Excel />} />
      </Routes>
    </>
  );
}

export default App;
