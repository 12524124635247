import React from 'react';
import { useLocation } from 'react-router-dom';
import '../Assets/headers.css'; 

const Header = () => {
  const location = useLocation();

  return (
    <div className="container">
      <header className="header d-flex flex-column align-items-center py-3 mb-4 border-bottom">
        <a href="/" className="link-body-emphasis text-decoration-none">
          <span className="fs-4 title">JP | Data Analyst</span>
        </a>

        {location.pathname === '/' && (
          <ul className="nav nav-pills mt-2">
            <li className="nav-item">
              <a href="#" className="nav-link">Home</a>
            </li>
            <li className="nav-item">
              <a href="#projects" className="nav-link">Projects</a>
            </li>
            <li className="nav-item">
              <a href="https://www.linkedin.com/in/jennifer-perez15" target="_blank" className="nav-link">Contact</a>
            </li>
          </ul>
        )}
      </header>
    </div>
  );
};

export default Header;
